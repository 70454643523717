import React from 'react';
import Layout from '../components/layout';

const NotFound = ({ location }) => {

    return (
        <Layout location={location}>
            404 Not Found.
        </Layout>
    );

}

export default React.memo(NotFound);